import PropTypes from 'prop-types';
import React from 'react';
import useTranslatedText from '../../hooks/useTranslatedText';
import sortDepartmentOptions from '../../tools/sortDepartmentOptions';
import SuggestedButtons from './DepartmentButton';
import style from './SearchErrorPage.module.scss';

function SuggestDepartments({
  activeDepartment = '',
  elasticDepartmentData = null,
  searchTerm = '',
  onDepartmentChange = () => {},
}) {
  const departmentData = elasticDepartmentData || [];
  const departmentOptions = sortDepartmentOptions(departmentData);
  const newOptions = departmentOptions?.filter((department) => department.id !== activeDepartment && department.id !== 'All');
  const activeDepartmentName = departmentOptions?.find(
    (department) => department.id === activeDepartment,
  )?.name;
  const noResultsText = useTranslatedText('relatedResults', { fallback: 'Related results:' });
  const noResultsFor = useTranslatedText('noMatchFoundForInDepartment', {
    fallback: `No results found for "${searchTerm}" in ${activeDepartmentName}.`,
    replacements: [searchTerm, activeDepartmentName],
  });

  return (
    <div className={style.wrapper}>
      <h3 aria-live="assertive" className="search-title h3">{noResultsFor.value}</h3>
      <div className={style.buttonGroup}>
        <span>{noResultsText.value}</span>
        {newOptions?.map((department) => (
          <SuggestedButtons
            key={department.id}
            departmentId={department.id}
            departmentName={department.name}
            onDepartmentChange={onDepartmentChange}
            searchTerm={searchTerm}
          />
        ))}
      </div>
    </div>
  );
}

SuggestDepartments.propTypes = {
  activeDepartment: PropTypes.string,
  elasticDepartmentData: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    defaultCategoryIdBySite: PropTypes.string,
  })),
  searchTerm: PropTypes.string,
  onDepartmentChange: PropTypes.func,
};

export default SuggestDepartments;
